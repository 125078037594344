import axios from 'axios';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from './msalConfig';

const serviceApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

async function getAccessToken() {
    if (serviceApi.defaults.headers.common['Authorization']) {
        return;
    }

    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    const account = msalInstance.getAllAccounts()[0];
    const tokenRequest = {
        account,
        scopes: loginRequest.scopes // Replace with your API scopes
    }
    try {
        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        let accessToken = response.accessToken;
        serviceApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        return;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            if (msalInstance) {
                msalInstance.acquireTokenRedirect(tokenRequest);
            }
        }
    }
    try {
        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        return response.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            if (msalInstance) {
                msalInstance.acquireTokenRedirect(tokenRequest);
            }
        }
        throw error;
    }
}

export async function createSession(): Promise<string> {
    await getAccessToken();
    const sessionResponse = await serviceApi.post('/api/sessions/create');
    let sessionId = sessionResponse.data.sessionId as string;
    return sessionId;
}
export async function uploadFile(sessionId: string, file: File) {
    try {
        const updateSessionRequest = {
            SessionId: sessionId,
            FileName: file.name,
        };

        const updateResponse = await serviceApi.put('/api/sessions/item/add', updateSessionRequest);
        const { uploadUrl } = updateResponse.data;

        if (!uploadUrl) {
            throw new Error('No upload URL received.');
        }

        const uploadResponse = await fetch(uploadUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type || 'application/octet-stream',
                'x-ms-blob-type': 'BlockBlob',
            },
            body: file,
            mode: 'cors',
        });

        if (!uploadResponse.ok) {
            throw new Error(`Upload failed with status ${uploadResponse.status}`);
        }

        return uploadResponse;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
}

// export async function updateSession(sessionId: string, file: File, result: any) {
//     try {
//         await getAccessToken();

//         const updateSessionRequest = {
//             SessionId: sessionId,
//             FileName: file.name,
//             Transcription: result.text,
//             Status: 'ok',
//         };

//         // First, update session to get upload URL
//         const updateResponse = await serviceApi.put('/api/sessions/item/add', updateSessionRequest);
//         console.log('Update Session Response:', updateResponse);

//         const { uploadUrl } = updateResponse.data || {};
//         if (!uploadUrl) {
//             throw new Error('No upload URL received from updateSession call.');
//         }

//         // Now upload the file to the provided URL
//         const uploadResponse = await fetch(uploadUrl, {
//             method: 'PUT',
//             headers: {
//                 'Content-Type': file.type || 'application/octet-stream',
//                 'x-ms-blob-type': 'BlockBlob',
//             },
//             body: file,
//             mode: 'cors'
//         });

//         if (!uploadResponse.ok) {
//             throw new Error(`File upload failed with status ${uploadResponse.status}`);
//         }

//         console.log('File successfully uploaded:', uploadResponse);
//         return uploadResponse;
//     } catch (error) {
//         console.error('Error updating session and uploading file:', error);
//         throw error;
//     }
// }


export interface SessionView {
    sessionId: string;
    groupName: string; 
    name: string; 
    itemCount: number;
    characterCount: number;
    createAtUtc: Date;
}

export async function getSessions(): Promise<SessionView[]> {
    await getAccessToken();
    const response = await serviceApi.get('/api/sessions/all');
    return response.data.map((session: any) => ({
        sessionId: session.sessionId,
        groupName: session.groupName,
        name: session.name, 
        itemCount: session.itemCount,
        characterCount: session.characterCount,
        createAtUtc: new Date(session.createAtUtc),
    }));
}

export interface SessionItemView {
    id: string;
    fileName: string;
    audioUrl: string;
    transcription: string;
    status: string;
    createdAtUtc: Date;
}

export interface SessionDetailView {
    sessionId: string;
    groupName: string; // Added GroupName property
    name: string; // Added name property
    itemCount: number;
    characterCount: number;
    createAtUtc: Date;
    items: SessionItemView[];
}

export async function getSessionDetails(sessionId: string): Promise<SessionDetailView> {
    await getAccessToken();
    const response = await serviceApi.get(`/api/sessions/by-id/${sessionId}`);
    const session = response.data;
    return {
        sessionId: session.sessionId,
        groupName: session.groupName, 
        name: session.name, 
        itemCount: session.itemCount,
        characterCount: session.characterCount,
        createAtUtc: new Date(session.createAtUtc),
        items: session.items.map((item: any) => ({
            id: item.id,
            fileName: item.fileName,
            transcription: item.transcription,
            audioUrl: item.audioUrl,
            status: item.status,
            createdAtUtc: new Date(item.createdAtUtc),
        })),
    };
}

export interface UsageEOMView {
    id: string;
    eom: Date;
    characterCount: string;
}

export async function getUsageByEOM(): Promise<UsageEOMView[]> {
    await getAccessToken();
    const response = await serviceApi.get('/api/Usage/by_eom');
    return response.data.map((usage: UsageEOMView) => ({
        id: usage.id,
        eom: usage.eom,
        characterCount: usage.characterCount,
    }));
}

export async function updateSessionName(sessionId: string, name: string): Promise<void> {
    try {
        await getAccessToken();
        const updateNameRequest = {
            SessionId: sessionId,
            Name: name,
        };
        await serviceApi.put('/api/sessions/update/name', updateNameRequest);
    } catch (error) {
        console.error('Error updating session name:', error);
        throw error;
    }
}

export async function updateSessionGroupName(sessionId: string, groupName: string): Promise<void> {
    try {
        await getAccessToken();
        const updateGroupNameRequest = {
            SessionId: sessionId,
            GroupName: groupName,
        };
        await serviceApi.put('/api/sessions/update/group-name', updateGroupNameRequest);
    } catch (error) {
        console.error('Error updating session group name:', error);
        throw error;
    }
}
export interface GroupInfo {
    groupId: string;
    groupName: string;
   
}


export async function getSessionGroups(): Promise<GroupInfo[]> {
    try {
        await getAccessToken();
        const response = await serviceApi.get<GroupInfo[]>(`/api/sessions/group-info`);
        const groupInfoArray = response.data;
        return groupInfoArray;
    } catch (error) {
        console.error('Error updating session group name:', error);
        throw error;
    }

}

export async function addSessionGroup(groupName: string): Promise<void> {
    try {
        await getAccessToken();
        const groupNameAddRequest = {
            GroupName: groupName,
        };
        await serviceApi.post('/api/sessions/group-info/add', groupNameAddRequest);
        return;
    } catch (error) {
        console.error('Error updating session group name:', error);
        throw error;
    }
}

