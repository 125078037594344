import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import * as signalR from '@microsoft/signalr';
import { loginRequest, msalConfig } from './msalConfig';

export type CallbackFunction = (...args: any[]) => void;

class SignalRService {
  public connection: signalR.HubConnection;
  private msalInstance: PublicClientApplication;
  private static instance: SignalRService;

  public static getInstance(hub: string): SignalRService {
    if (!SignalRService.instance) {
      console.log('Creating new SignalRService instance.');
      SignalRService.instance = new SignalRService(hub);
    }

    return SignalRService.instance;
  }

  private constructor(hub: string) {
    this.msalInstance = new PublicClientApplication(msalConfig);
    let hubUrl = `${process.env.REACT_APP_API_URL}api/${hub}`;
    console.log(`Connecting to hub: ${hubUrl}`);  
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        transport: signalR.HttpTransportType.ServerSentEvents,
        accessTokenFactory: () => this.getAccessToken(),
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();
    

    this.connection.onclose(this.onClose);
    this.connection.start()
                   .then(() => console.log('Connection started successfully.'))
                   .catch(err => console.error('Connection error:', err));
    
  }

  private onClose(error?: Error | undefined) {
    if (error)
      console.log('Connection closed with error: ', error);
    else
      console.log('Connection closed with no error.');
  }

  public on(event: string, callback: CallbackFunction): void {
    console.log(`Registering callback for event: ${event}`);
    this.connection.on(event, callback);
  }

  public off(event: string, callback: CallbackFunction): void {
    console.log(`Removing callback for event: ${event}`);
    this.connection.off(event, callback);
  }

  public async stop(): Promise<void> {
    try {
      await this.connection.stop();
      console.log('Connection stopped successfully.');
    } catch (err) {
      console.error('Error stopping connection:', err);
    }
  }

  private async getAccessToken(): Promise<string> {
    await this.msalInstance.initialize();
    const account = this.msalInstance.getAllAccounts()[0];
    const tokenRequest = {
      account,
      scopes: loginRequest.scopes // Replace with your API scopes
    };

    try {
      const response = await this.msalInstance.acquireTokenSilent(tokenRequest);
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        if (this.msalInstance) {
          this.msalInstance.acquireTokenRedirect(tokenRequest);
        }
      }
      throw error;
    }
  }
}



export default SignalRService;
